import {Injectable} from '@angular/core'
import {BehaviorSubject, of, Subject, timer} from 'rxjs'
import {
  debounceTime,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators'
import {AUTO_SAVE_TIMEOUT} from '../common/interface/helpers'
import {IOpenProjectChange, OpenProjectService} from './open-project.service'

@Injectable({
  providedIn: 'root'
})
export class AutoSaveService {

  public saving$ = new BehaviorSubject<boolean>(false)

  public timer$ = new BehaviorSubject<number>(0)

  public changed$ = new BehaviorSubject<boolean>(false)

  private currentChanges: IOpenProjectChange = {}
  private cancelTimer: Subject<any> = new Subject<any>()

  constructor(
    private openProjectService: OpenProjectService
  ) {
    this.openProjectService.openProjectChanges$
      .pipe(
        map((changes: IOpenProjectChange) => {
          // Cancel potential ongoing saves, and start new countdown.
          // But only if new changes have a change for real.
          if (this.wasThereAChange(changes)) {
            this.cancelTimer.next(null)
            this.countDown()
          }

          // Save current changes and get if there was a change for real
          this.currentChanges = {...changes}
          const wasThereAChange = this.wasThereAChange(this.currentChanges)

          // Send new event in subject for components to listen
          this.changed$.next(wasThereAChange)

          return wasThereAChange
        }),
        filter(Boolean),
        debounceTime(AUTO_SAVE_TIMEOUT)
      )
      .subscribe({
        next: () => {
          // Once saving timer is off, send cancellation event and save changes
          this.cancelTimer.next(null)
          this.saveChanges()
        }
      })
  }

  /**
   * Function that is called either manually by user clicking "Save" button in
   * header, or automatically once auto-save-countdown is finished.
   * Either way, it will get last saved "currentChanges" variable and send it
   * to service to save all changes.
   *
   * We don't care about the output of this saving event. We just do it.
   */
  public saveChanges(): void {
    of(this.currentChanges)
      .pipe(
        first(),
        // Reset current changes
        tap(() => {
          this.currentChanges = {}
        }),
        // Only perform changes if there was actually a change
        filter(this.wasThereAChange),
        // Set saving flag to true. Activate saving loader
        tap(() => {
          this.saving$.next(true)
        }),
        // Save all changes
        switchMap((changes) =>
          this.openProjectService.saveOpenProjectBasedOnChanges(changes))
      )
      .subscribe(() => {
        // Reset saving flag to false. Deactivate saving loader.
        this.saving$.next(false)
      })
  }

  /**
   * This is just for setting the clock.
   */
  private countDown(): void {
    this.timer$.next(0)

    timer(0, 1000).pipe(
      takeUntil(this.cancelTimer),
      tap((value) => {
        this.timer$.next(value)
      })
    ).subscribe()
  }

  private wasThereAChange(changes: IOpenProjectChange): boolean {
    return Object.keys(changes).some(key => changes[key] === true)
  }
}
